import { render } from 'react-dom';
import React from 'react';
import { IntlProvider } from "react-intl";
import messages from "../../translations/pl";
import ButtonAppBar from "../navbar";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { themeNavbar } from "../../config/theme";
$(document).ready(function () {
    const theme = createMuiTheme(themeNavbar);
    render(<IntlProvider locale={"pl-PL"} messages={messages}>
            <ThemeProvider theme={theme}>
                <ButtonAppBar />
            </ThemeProvider>
        </IntlProvider>, document.getElementById('react-navbar'));
});
