export default {
    'decrement': 'decrement',
    'increment': 'increment',
    'sm.name': 'Shopping mall name',
    'Country': 'Country',
    'Country-rank': 'Country rank',
    'City': 'City',
    'Users': 'Users',
    'Visits': 'Visits',
    'Visits-of-total': 'Visits (% of Total)',
    'Ranking': 'Ranking',
    'Total-visits': 'Total visits',
    'Close': 'Close',
    'Next': 'Next',
    'SaveAndNext': 'Save and Next',
    'Back': 'Back',
    'Apply': 'Apply',
    'Shopping-malls': 'Shopping malls',
    'PERIOD': 'PERIOD',
    'PERIOD90Days': 'PERIOD - You can select max 90 days',
    'PERIOD180Days': 'PERIOD - You can select max 180 days',
    'FREQUENCY': 'FREQUENCY',
    'CreateSegment': 'Create segment',
    'CreateReport': 'Create report',
    'ShowResults': 'Show results',
    'DownloadCsv': 'Download (csv)',
    'Delete': 'Delete',
    'Edit': 'Edit',
    'Duplicate': 'Duplicate',
    'Segments': 'Segments',
    'Yesterday': 'Yesterday',
    'Today': 'Today',
    'NumberOfStores': 'Number of stores',
    'GoToDrive': 'Go to drive',
    'card.total-visits.0': 'Attention! Your visits declined by {diff, number}% to {value, number} visits.',
    'card.total-visits.1': 'Be aware! Your visits declined by {diff, number}% to {value, number} visits.',
    'card.total-visits.2': 'Great results! Your visits increased by {diff, number}% to {value, number} visits.',
    'card.total-visits.3': 'You are rocking it! Your visits increased by {diff, number}% to {value, number} visits.',
    'card.conversion.0': 'Attention! Your locations declined by {diff, number}% in converting visitors.',
    'card.conversion.1': 'Be aware! Your locations declined by {diff, number}% in converting visitors.',
    'card.conversion.2': 'Amazing! Your locations increased by {diff, number}% in converting visitors.',
    'card.conversion.3': 'Awesome! Your locations increased by {diff, number}% in converting visitors.',
    'card.new-customers.0': 'Attention! Your new visitors declined by {diff, number}% to {value, number} visits.',
    'card.new-customers.1': 'Be aware! Your new visits declined by {diff, number}% to {value, number} visits.',
    'card.new-customers.2': 'Great results! Your new visits increased by {diff, number}% to {value, number} visits.',
    'card.new-customers.3': 'You are rocking it! Your new visits increased by {diff, number}% to {value, number} visits.',
    'card.returning-customers.0': 'Attention! Your returning visitors declined by {diff, number}% to {value, number} visits.',
    'card.returning-customers.1': 'Be aware! Your returning visitors declined by {diff, number}% to {value, number} visits.',
    'card.returning-customers.2': 'Great results! Your returning visitors increased by {diff, number}% to {value, number} visits.',
    'card.returning-customers.3': 'You are rocking it! Your returning visitors increased by {diff, number}% to {value, number} visits.',
    'card.compare.total-visits.0': 'Selection 1 generated {subtraction, number} compare to Selection 2',
    'card.compare.total-visits.1': 'Selection 1 generated {subtraction, number} compare to Selection 2',
    'card.compare.total-visits.2': 'Selection 1 generated {subtraction, number} compare to Selection 2',
    'card.compare.total-visits.3': 'Selection 1 generated {subtraction, number} compare to Selection 2',
    'card.compare.conversion.0': 'Selection 1 converts {subtractionPercent, number}% visits less compare to Selection 2',
    'card.compare.conversion.1': 'Selection 1 converts {subtractionPercent, number}% visits less compare to Selection 2',
    'card.compare.conversion.2': 'Selection 1 converts {subtractionPercent, number}% visits more compare to Selection 2',
    'card.compare.conversion.3': 'Selection 1 converts {subtractionPercent, number}% visits more compare to Selection 2',
    'card.compare.new-customers.0': 'Selection 1 generated {subtraction, number} new visitors compare to Selection 2',
    'card.compare.new-customers.1': 'Selection 1 generated {subtraction, number} new visitors compare to Selection 2',
    'card.compare.new-customers.2': 'Selection 1 generated {subtraction, number} new visitors compare to Selection 2',
    'card.compare.new-customers.3': 'Selection 1 generated {subtraction, number} new visitors compare to Selection 2',
    'card.compare.returning-customers.0': 'Selection 1 generated {subtraction, number} returning visitors compare to Selection 2',
    'card.compare.returning-customers.1': 'Selection 1 generated {subtraction, number} returning visitors compare to Selection 2',
    'card.compare.returning-customers.2': 'Selection 1 generated {subtraction, number} returning visitors compare to Selection 2',
    'card.compare.returning-customers.3': 'Selection 1 generated {subtraction, number} returning visitors compare to Selection 2',
    'graph.visits.summary.best': 'Your best period accounted for {diff, number}% ({value, number} visits) during {date, date}.',
    'graph.visits.summary.worst': 'Your weakest period accounted for {diff, number}% ({value, number} visits) during {date, date}.',
    'card.title.all-visits': 'All visits',
    'card.title.sm-stores-visits': 'Shopping malls store visits',
    'card.title.total-user-visits': 'Shopping malls users',
    'card.title.analyzed-sm': 'Analyzed shopping malls',
    'card.title.stores-in-sn': 'Stores in shopping malls',
    'card.title.active-sm-users': 'Active shopping malls users',
    'card.title.trending-sm': 'Trending shopping malls',
    'card.title.trending-shops-in-sm': 'Trending store in shopping malls',
    'card.sm.all-visits': 'All shopping malls visits in {country}, {change} by {diff, number}%',
    'card.sm.store-visits': 'All shopping malls visits in {country}, {change} by {diff, number}%',
    'card.sm.analyzed-sm': '{country} have {numberOfSM, number} active shopping malls',
    'card.sm.stores-in-sn': 'TBD',
    'card.sm.total-sm-visits': 'TBD',
    'card.sm.trending-sm': 'TBD',
    'card.sm.trending-shops-in-sm': 'TBD',
    'date-range.Previous-period': 'Previous period: {from} - {to}',
    'date-range.Current-period': 'Current period: {from} - {to}',
    'date-range.Current-period-compare': 'Comparison period: {from} - {to}',
    'date-range.Compare-set': 'You are comparing set 1 with set 2',
    'date-range.Choose-previous-period': 'Choose period',
    'date-range.compare-to-type.previous': 'Previous period',
    'date-range.compare-to-type.chosen-period': 'Chosen period',
    'date-range.Compare': 'Compare',
    'date-range.Compare-to': 'Compare to',
    'performance.rank': 'Rank',
    'performance.sm': 'Shopping mall',
    'performance.city': 'City',
    'sm.analytics.performance': "Shopping mall ranking in {country}",
    'sm.analytics.market-share': "Market share in {country}",
    'sm.analytics.stats-table': "Shopping malls performance summary in {country}",
    'sm.analytics.top-performing': "Top performing shopping malls in {country}",
    'Select-country': 'Select country',
    'Select-city': 'Select city',
    'Select-shopping-mall': 'Select shopping mall',
    'audienceStatus0': 'Calculating...',
    'audienceStatus1': 'Calculating...',
    'audienceStatus2': 'Completed',
    'viewBycity': 'City',
    'viewBybrand': 'Brand',
    'viewBystore': 'Point of interest',
    'viewByshoppingMall': 'Shopping mall',
    'viewBycategory': 'Category',
    'viewBytaxonomy': 'Taxonomy',
    'dataByusers': 'Users',
    'dataByvisits': 'Visits',
    'ALLvisits': 'ALL VISITS',
    'ALLusers': 'ALL USERS',
    'filtersAllshoppingMall': 'All ({count, number}) shopping malls',
    'filtersAllcity': 'All ({count, number}) cities',
    'filtersAllbrand': 'All ({count, number}) brands',
    'filtersAllcountry': 'All ({count, number}) countries',
    'filtersAllcategory': 'All ({count, number}) categories',
    'filtersAlltaxon': 'All ({count, number}) taxonomy',
    'performanceDefaultCardAllcity': 'All cities',
    'performanceDefaultCardAllbrand': 'All brands',
    'performanceDefaultCardAllshoppingMall': 'All shopping malls',
    'performanceDefaultCardAllcategory': 'All categories',
    'performanceDefaultCardAllstore': 'All stores',
    'performanceDefaultCardTrendingcity': 'Trending cities',
    'performanceDefaultCardTrendingbrand': 'Trending brands',
    'performanceDefaultCardTrendingshoppingMall': 'Trending shopping malls',
    'performanceDefaultCardTrendingcategory': 'Trending categories',
    'performanceDefaultCardTrendingstore': 'Trending stores',
    'segments_reports.showing.entries': 'Found {new} entries (filtered from {total} total entries)',
    'segments_reports.button.csv': 'CSV',
    'segments_reports.search.placeholder': 'Search...',
    'tool.brand.label': 'Brand',
    'tool.button.apply': 'Apply',
    'tool.button.apply.loading': 'Loading',
    'tool.button.generate': 'Generate',
    'tool.category.label': 'Category',
    'tool.city.label': 'City',
    'tool.country.label': 'Country',
    'tool.date_range.label': 'Date Range',
    'tool.date_range.item.label.last_full_week': 'Last Full Week',
    'tool.date_range.item.label.last_full_month': 'Last Full Month',
    'tool.date_range.item.label.custom': 'Custom',
    'tool.search.label': 'Search',
    'tool.shoppingMall.label': 'Shopping Mall',
    'tool.taxonomy.label': 'Taxonomy',
    'tool.taxonomy.empty.label': 'No data or data is loading.',
    'tool.view_by.label': 'View By',
    'error.response.data': 'Sorry. Server unavailable or have technical problems! Please contact our support!',
    'error.request.segments_reports.params': 'There is bad data of report params! Please contact our support!',
    'info.taxonomy.header': 'Taxonomy',
    'info.taxonomy.header.name': 'Name',
    'info.taxonomy.header.light': 'Light',
    'info.taxonomy.header.medium': 'Medium',
    'info.taxonomy.header.heavy': 'Heavy',
    'info.taxonomy.header.all': 'All',
    'info.taxonomy.node.brand.header': 'Brands'
};
