window.mapIcons = {};
mapIcons['pending'] = L.ExtraMarkers.icon({
    icon: 'fa-spinner',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['clothes'] = L.ExtraMarkers.icon({
    icon: 'fa-tshirt',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['mall'] = L.ExtraMarkers.icon({
    icon: 'fa-warehouse',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['stadium'] = L.ExtraMarkers.icon({
    icon: 'fa-futbol',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['car_dealer'] = L.ExtraMarkers.icon({
    icon: 'fa-car-side',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['pharmacy'] = L.ExtraMarkers.icon({
    icon: 'fa-clinic-medical',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['bank'] = L.ExtraMarkers.icon({
    icon: 'fa-money-bill-alt',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['default'] = L.ExtraMarkers.icon({
    icon: 'fa-circle',
    markerColor: '#3f51b5',
    iconColor: '#b5b2f1',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['warning'] = L.ExtraMarkers.icon({
    icon: 'fa-arrows-alt',
    markerColor: 'orange',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
// mapIcons['default'] = new L.Icon({
//     iconUrl: '/assets/images/marker/marker-icon-2x-blue.png',
//     shadowUrl: '/assets/images/marker/marker-shadow.png',
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//     popupAnchor: [1, -34],
//     shadowSize: [41, 41]
// });
// mapIcons['warning'] = new L.Icon({
//     iconUrl: '/assets/images/marker/marker-icon-2x-blue.png',
//     shadowUrl: '/assets/images/marker/marker-shadow.png',
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//     popupAnchor: [1, -34],
//     shadowSize: [41, 41]
// });

window.getMapIcons = function (index) {
    if(index) {
        index = index.replace(/\s+/g, '_');
    }
    if(mapIcons[index]) {
        return mapIcons[index];
        // return mapIcons['default'];
    } else {
        return mapIcons['default']
    }
};

mapIcons['restaurant'] = L.ExtraMarkers.icon({
    icon: 'fa-utensils',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['convenience'] = L.ExtraMarkers.icon({
    icon: 'fa-shopping-basket',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['hotel'] = L.ExtraMarkers.icon({
    icon: 'fa-hotel',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['insurance'] = L.ExtraMarkers.icon({
    icon: 'fa-user-shield',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['supermarket'] = L.ExtraMarkers.icon({
    icon: 'fa-shopping-cart',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['fuel'] = L.ExtraMarkers.icon({
    icon: 'fa-gas-pump',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['fast_food'] = L.ExtraMarkers.icon({
    icon: 'fa-hamburger',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['exhibition_hall'] = L.ExtraMarkers.icon({
    icon: 'fa-monument',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['cosmetics'] = L.ExtraMarkers.icon({
    icon: 'fa-spray-can',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['shoes'] = L.ExtraMarkers.icon({
    icon: 'fa-shoe-prints',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['sport_store'] = L.ExtraMarkers.icon({
    icon: 'fa-table-tennis',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['lingerie'] = L.ExtraMarkers.icon({
    icon: 'fa-venus',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['furniture'] = L.ExtraMarkers.icon({
    icon: 'fa-chair',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['post_office'] = L.ExtraMarkers.icon({
    icon: 'fa-envelope',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['bakery'] = L.ExtraMarkers.icon({
    icon: 'fa-bread-slice',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['jewelry'] = L.ExtraMarkers.icon({
    icon: 'fa-gem',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['electronics'] = L.ExtraMarkers.icon({
    icon: 'fa-laptop-medical',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['doityourself'] = L.ExtraMarkers.icon({
    icon: 'fa-hammer',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['sport_place'] = L.ExtraMarkers.icon({
    icon: 'fa-running',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['car_premium'] = L.ExtraMarkers.icon({
    icon: 'fa-car-alt',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['cafe'] = L.ExtraMarkers.icon({
    icon: 'fa-coffee',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['community_centre'] = L.ExtraMarkers.icon({
    icon: 'fa-building',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'far',
    svg: true
});
mapIcons['fit'] = L.ExtraMarkers.icon({
    icon: 'fa-dumbbell',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['cinema'] = L.ExtraMarkers.icon({
    icon: 'fa-film',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['branded_electronics'] = L.ExtraMarkers.icon({
    icon: 'fa-vote-yea',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['kindergarten'] = L.ExtraMarkers.icon({
    icon: 'fa-fan',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['houseware'] = L.ExtraMarkers.icon({
    icon: 'fa-fan',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['trave_agency'] = L.ExtraMarkers.icon({
    icon: 'fa-globe-europe',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['baby_goods'] = L.ExtraMarkers.icon({
    icon: 'fa-baby',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['discounter'] = L.ExtraMarkers.icon({
    icon: 'fa-percentage',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['telecom'] = L.ExtraMarkers.icon({
    icon: 'fa-mobile-alt',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});
mapIcons['airport'] = L.ExtraMarkers.icon({
    icon: 'fa-plane-departure',
    markerColor: '#3f51b5',
    shape: 'circle',
    prefix: 'fas',
    svg: true
});

//
//
//
// medical place
// beauty
// computer
// butcher
// hairdresser
// discounter
// optician
// variety store
// post_office
// bookmaker
// pastry
// theatre
// pet
// business
// atm
// bicycle_rental
// dentist
// marketplace
// airport
// interior_decoration
// townhall
// ice_cream
// school
// vending_machine
// food
// public_building
// car_parts
// chemist
// amusement park
// stock exchange
// vehicle_inspection
// university
// ski lift
// arts centre
// kiosk
// hospital
// books
// car_repair
// bus/tram stop
// alcohol
// park
