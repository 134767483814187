export const themeNavbar = {
    palette: {
        primary: {
            light: '#3f51b5',
            main: '#3f51b5',
            dark: '#0D2194',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#5bedcb',
            main: '#00ba9a',
            dark: '#00896c',
            contrastText: '#ffffff',
        },
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#3f51b5'
            }
        }
    }
};
