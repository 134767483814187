import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontFamily: "DraftA-Medium",
        fontWeight: "bold",
        marginTop: '2px',
        color: '#ffffff',
        cursor: 'pointer'
    },
    logo: {
        maxWidth: 120,
    },
}));
export default function ButtonAppBar(props) {
    const classes = useStyles('theme');
    return (<div className={classes.root}>
            <AppBar elevation={props.elevation} position="static">
                <Toolbar>
                    <IconButton id="hamburger-menu" edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title} onClick={() => location.reload()}>
                        KoalaMetrics
                    </Typography>
                    <img src="/assets/images/logo-justtag-group-white.png" alt="Justtag Group" className={classes.logo}/>
                </Toolbar>
            </AppBar>
        </div>);
}
ButtonAppBar.defaultProps = {
    elevation: 4
};
