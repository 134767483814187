/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.scss in this case)
require('../css/app.scss');
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/js/src/popover'
window.Tabulator = require('tabulator-tables');
require('leaflet');
require('leaflet.markercluster');
// require('leaflet.awesome-markers');
require('leaflet-extra-markers');
require('./map/icons');

// import '~leaflet.awesome-markers/dist/leaflet.awesome-markers.css';

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
$(document).ready(function(){
    $('[data-toggle="popover"]').popover();

});
$(document).ready(function () {
    $('body').on('click', '#hamburger-menu, .closebtn', function() {
        const $main = $('#main');
        const $sidenav = $('#sidenav');
        const $body = $('body');

        if ($body.hasClass('active-sidebar')) {
            $main.css('marginLeft', '0');
            $sidenav.css('width', '0');
            $body.removeClass('active-sidebar');
        } else {
            $body.addClass('active-sidebar');
            $main.css('marginLeft', '250px');
            $sidenav.css('width', '250px');
        }
    });
});
import './pages/panel'


